import { graphql } from "gatsby"
import "../stylesheets/general.css"
import React from "react"
import {
  Button,
  Card,
  Content,
  Hero,
  Layout,
  ResultCard,
  Subscribe,
} from "../components"
import SEO from "../components/SEO"
import { ResultCardGrid } from "../elements"

const ResultsPage = ({ data }) => {
  const results = data.allWordpressWpResults.nodes
  return (
    <Layout>
      <SEO title={"Results"} description={"We create effective growth strategies and innovative solutions for leading brands delivering the exciting products and services of today while building the future."} />
      <Hero
        image={data.heroImage.fluid}
        title="Pioneers In Putting Innovation To Work."
        description="We create effective growth strategies and innovative solutions for leading brands delivering the exciting products and services of today while building the future."
        videoUrl="https://itspixelperfect.s3.amazonaws.com/videos/superbowl.mp4"
        // videoId="xojcsQNztCw"
        hasBgVideo={true}
      />
      <Content spacing>
        <Card
          name="Data. Actions. Outcomes."
          margin="0 0 82px 0"
          title="In a dynamic global environment, data-driven digital transformation is a business imperative."
          description="By leveraging our culture of constant learning and innovation, we use a diverse and impactful range of strategy, design, and technology capabilities to deliver game-changing outcomes for our clients at any level, everywhere. We like it when our work speaks for us. Take a look."
        />

        <ResultCardGrid>
          {results.map((result, index) => (
            <ResultCard key={index} result={result} />
          ))}
        </ResultCardGrid>
      </Content>
      <Subscribe />
    </Layout>
  )
}
export const query = graphql`
  query {
    heroImage: imageSharp(fluid: { originalName: { eq: "resultshero.png" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    allWordpressWpResults(limit: 4) {
      nodes {
        content
        slug
        title
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 430, maxHeight: 590) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
export default ResultsPage
